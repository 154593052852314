import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/front-end-performance",
  "date": "2016-03-12",
  "title": "FRONT END PERFORMANCE",
  "author": "admin",
  "tags": ["development", "performance"],
  "featuredImage": "feature.jpg",
  "excerpt": "Front end performance can be a great experience or a horrifying nightmare that could highly impact the end user experience, and lead to users leaving your website."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`In reality most web pages spend 10-20% of the end user response time spent on getting the content from the web server top the browser. The rest, 80-90% will be spent on rendering the web page, loading components etc.`}</p>
    <Message type="info" title="Parallel Requests" content="HTTP requests can happen in parallel. This could be due to multiple hostnames being used or as a result of using `HTTP/1.0` or `HTTP/1.1`.
  Parallel requests don't happen with script downloads, because browser blocks HTTP requests when downloading scripts." mdxType="Message" />
    <h2>{`Optimizing Web Performance`}</h2>
    <p>{`Let's examine the rules for web performance optimization.`}</p>
    <ul>
      <li parentName="ul">{`Make fewer HTTP Requests`}</li>
      <li parentName="ul">{`Use a Content Delivery Network (CDN)`}</li>
      <li parentName="ul">{`Add an Expires Header`}</li>
      <li parentName="ul">{`Gzip Components`}</li>
      <li parentName="ul">{`Put Stylesheets at the Top`}</li>
      <li parentName="ul">{`Put Scripts at the Bottom`}</li>
      <li parentName="ul">{`Avoid CSS Expressions`}</li>
      <li parentName="ul">{`Make JavaScript & CSS External`}</li>
      <li parentName="ul">{`Reduce DNS Lookups`}</li>
      <li parentName="ul">{`Minify JavaScipt`}</li>
      <li parentName="ul">{`Avoid Redirects`}</li>
      <li parentName="ul">{`Remove Duplicate Scripts`}</li>
      <li parentName="ul">{`Configure Etags`}</li>
    </ul>
    <h2>{`Make fewer HTTP Requests`}</h2>
    <p>{`When your web page loads in a browser, the browser sends an HTTP request to the web server for the page in the URL. Then, as the HTML is delivered, the browser parses it and looks for additional requests for images, scripts, CSS, and so on. Every time it sees a request for a new element, it sends another HTTP request to the server. `}</p>
    <h3>{`How to Reduce HTTP Requests Without Effecting Your Design?`}</h3>
    <p>{`Luckily, there are several ways you can reduce the number of HTTP requests, while maintaining high-quality, rich web designs.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Combined files`}</inlineCode>{` - Using external style sheets and scripts is important to keep them from bogging down your page load times, but don’t have more than one CSS and one script file.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CSS Sprites`}</inlineCode>{` - When you combine most or all of your images into a sprite, you turn multiple images requests into just one. Then you just use the background-image CSS property to display the section of the image you need.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Image Maps`}</inlineCode>{` - Image maps are not as popular as they once were, but when you have contiguous images they can reduce multiple HTTP image requests down to just one.`}</li>
    </ul>
    <Message type="info" title="Advantage of Caching" content="By utilizing CSS sprites and combined CSS and scripts, you can likewise enhance load times for internal pages. For instance, in the event that you have a sprite picture that contains components of inside pages and in addition your presentation page, then when your readers go to those inward pages, the image is now downloaded and exists in the cache." mdxType="Message" />
    <h2>{`Use a Content Delivery Network (CDN)`}</h2>
    <p>{`A content delivery network (CDN) is a gathering of web servers circulated over different areas to convey content all the more effectively to clients.`}</p>
    <div className="info">
 The user's proximity to your web server has an impact on response times. Deploying your content across multiple, geographically dispersed servers will make your pages load faster from the user's perspective. 
- **Yahoo!**
    </div>
    <h2>{`Add an Expires Header`}</h2>
    <p>{`Express headers as stated, instructs the browser to requests a file from either the cache or the server. This will dramatically decrease the amount of repeated HTTP requests and hence increase the load time.`}</p>
    <Message type="info" title="" content="Adding an Expires header to your components with a date in the future makes them cacheable, reducing the load time of your pages. Certainly this should be done with images, but that's fairly typical. Go a step further and add it to scripts and stylesheets, too. This won't affect performance the first time users hit your page, but on subsequent page views it could reduce response times by 50% or more. 
- **Steve Souders**" mdxType="Message" />
    <h2>{`Gzip Components`}</h2>
    <p>{`Compressed components allow your server to provide smaller file sizes that load faster for your clients.`}</p>
    <p>{`It said to be 50%-70% of file size saving for HTML and CSS when it is compressed and your pages will load much faster, and saves bandwidth.`}</p>
    <Message type="info" title="" content="Next to eliminating unnecessary resource downloads, the best thing we can do to improve page load speed is to minimize our overall download size by optimizing and compressing the remaining resources. 
- **Google**" mdxType="Message" />
    <h2>{`Put Stylesheets at the Top`}</h2>
    <p>{`Placing your CSS at the top in the `}{`<`}{`head`}{`>`}{` section avoid users seeing a blank page at the load time and content will be styled before the blocking JavaScript files are being downloaded.`}</p>
    <Message type="info" title="" content="The HTML specification clearly states that stylesheets are to be included in the HEAD of the page: ~Unlike A, [LINK] may only appear in the HEAD section of a document, although it may appear any number of times.~ Neither of the alternatives, the blank white screen or flash of unstyled content, are worth the risk. The optimal solution is to follow the HTML specification and load your stylesheets in the document HEAD. 
- **Steve Souders **" mdxType="Message" />
    <h2>{`Put Scripts at the Bottom`}</h2>
    <p>{`In order to prevent resources being blocked by the scripts at the download stage, it is the best practice to put the scripts at the bottom.`}</p>
    <Message type="info" title="" content="JavaScript scripts block parallel downloads; that is, when a script is downloading, the browser will not start any other downloads.
If you serve your images from multiple hostnames, you can get more than two downloads to occur in parallel. While a script is downloading, however, the browser won't start any other downloads, even on different hostnames. 
- **Yahoo!**" mdxType="Message" />
    <h2>{`Avoid CSS Expressions`}</h2>
    <p>{`CSS expressions are a feature of IE5-IE7. They are a dangerous way of setting CSS properties dynamically.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`background-color: expression( (new Date()).getHours()%2 ? "#FF0000" : "#00FF00" );
`}</code></pre>
    <p>{`These expressions would affect the browser performance, as they are evaluated more frequently.`}</p>
    <Message type="info" title="" content="One way to reduce the number of times your CSS expression is evaluated is to use one-time expressions, where the first time the expression is evaluated it sets the style property to an explicit value, which replaces the CSS expression. If the style property must be set dynamically throughout the life of the page, using event handlers instead of CSS expressions is an alternative approach. If you must use CSS expressions, remember that they may be evaluated thousands of times and could affect the performance of your page. 
- **Yahoo!**" mdxType="Message" />
    <h2>{`Make JavaScript & CSS External`}</h2>
    <p>{`Because the browser will cache the external files, making CSS and JavaScript external will increase the page speed.`}</p>
    <h2>{`Reduce DNS Lookups`}</h2>
    <p>{`A browser must resolve the DNS name of the web server to an IP address, before a browser can establish a network connection to a web server. Since DNS resolutions can be cached by the client's browser and operating system, if a valid record is still available in the client's cache, there is no latency introduced.`}</p>
    <Message type="info" title="" content="High Performance Web Sites: Rule 9 – Reduce DNS Lookups. The Domain Name System (DNS) maps hostnames to IP addresses, just as phonebooks map people's names to their phone numbers. When you type www.yahoo.com into your browser, a DNS resolver contacted by the browser returns that server's IP address. 
- **Yahoo! **" mdxType="Message" />
    <h2>{`Minify JavaScript`}</h2>
    <p>{`Minifying JavaScript will obviously reduce the time it takes to load a web page.`}</p>
    <h2>{`Avoid Redirects`}</h2>
    <p>{`A redirect between the final HTML file and the user, will delay the page from loading as no component can be downloaded until HTML document has arrived.`}</p>
    <Message type="info" title="" content="Redirects trigger an additional HTTP request-response cycle and delay page rendering. In the best case, each redirect will add a single roundtrip (HTTP request-response), and in the worst it may result in multiple additional roundtrips to perform the DNS lookup, TCP handshake, and TLS negotiation in addition to the additional HTTP request-response cycle. As a result, you should minimize use of redirects to improve site performance. 
- **Google **" mdxType="Message" />
    <h2>{`Remove Duplicate Scripts`}</h2>
    <p>{`Including the same JavaScript file twice obviously slow down the performance.`}</p>
    <h2>{`Configure Etags`}</h2>
    <p>{`To prevent 304 responses, we should not run multiple servers with the default Etag settings.`}</p>
    <Message type="info" title="" content="Since ETags are typically constructed using attributes that make them unique to a specific server hosting a site, the tags will not match when a browser gets the original component from one server and later tries to validate that component on a different server. 
- **Yahoo! **" mdxType="Message" />
    <h2>{`Conclusion`}</h2>
    <p>{`These rules we discussed by Yahoo, will significantly increase the speed and the performance of your websites.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      